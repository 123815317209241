body {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  font-face: Quicksand;
}


@font-face {
  font-family: Quicksand;
  src: url('../fonts/Quicksand-VariableFont_wght.ttf');
}